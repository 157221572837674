import { ErrorPage404 } from '@b7hio/core-lib/components';
import { GetStaticProps } from 'next';
import { homeConfig } from '../features/home/home.config';
import { ssrTranslations } from '../hooks/ssr-translations';

export default function Custom404() {
  return <ErrorPage404 />;
}
export const getStaticProps: GetStaticProps = async (ctx) => {
  const tProps = await ssrTranslations(ctx, homeConfig.i18nNamespaces);

  return {
    props: {
      ...tProps,
    },
  };
};
